.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  
}
.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal-content {
  text-align: center;
}

.cover-image {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.close-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-button:hover {
  background-color: #0056b3;
}

.Grammar {
  /* Set the maximum width of the container and center it */
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  /* Add some space around the container */
}

.Grammar p, .Grammar button, .Grammar img {
  /* Add some margin to elements inside Grammar for better spacing */
  margin-bottom: 15px;
}

.Grammar button {
  /* Style the buttons a bit */
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.Grammar button:hover {
  /* Slightly darken button on hover for feedback */
  background-color: #0056b3;
}

.Grammar img {
  /* Ensure images are not too large */
  max-width: 100%;
  height: auto;
}

.instructions .text {
  font-family: sans-serif;
}

.instructions .italic {
  font-style: italic;
  font-family: sans-serif;
}

.instructions .bold {
  font-weight: bold;
  font-family: sans-serif;
}

.instructions .underline {
  text-decoration: underline;
}

.instructions .strike {
  text-decoration: line-through;
}

.instructions .paragraph {
  margin-bottom: 10px; /* Adjust as needed */
}

.instructions .default {
  font-family: sans-serif; 
}
.main-nav {
  position: relative;
  left: 0;
  top: 0;
  height: 100%;
  width: 200px;
  background-color: #f0f0f0; /* Light gray background for the frame */
  border-right: 2px solid #ccc; /* Border to the right of the frame */
  padding-top: 20px; /* Padding at the top */
}
/* Styling for the menu list */
.menu-list {
  list-style: none; /* Remove default list styling */
  padding: 0;
}

/* Styling for each menu item */
.menu-list li {
  margin: 20px 0; /* Space between menu items */
}

/* Styling for the links */
.menu-list li a {
  font-size: 24px; /* Font size for the menu items */
  color: #333; /* Text color */
  text-decoration: none; /* Remove underline */
}

.menu-list li a:hover {
  color: #007BFF; /* Change color on hover */
}

/* Styling for word bank activity paragraphs */
.wordbank-paragraph {
  font-family: 'Arial, sans-serif';
  font-size: 18px; /* Larger size for readability */
  line-height: 1.6;
  color: #333; /* Darker color for better contrast */
}

/* Styling for operation messages in word bank activities, e.g., 'Your Selections' */
.wordbank-operation-message {
  font-family: 'Arial, sans-serif';
  font-size: 16px; /* Smaller font for less emphasis */
  font-weight: bold; /* Keep it bold to distinguish it from normal text */
  color: #007bff; /* Blue color for visual distinction */
  margin-top: 10px; /* Space above the operation message */
}

/* Styling for Multiple Choice Container */
.multiple-choice-container {
  margin: 20px;
  padding: 20px;
  background-color: #f9f9f9; /* Optional: light background to separate content */
  border-radius: 8px; /* Rounded corners for a polished look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow for depth */
}

/* Styling for Multiple Choice Question */
.multiple-choice-question {
  font-size: 22px; /* Increase font size for better readability */
  font-weight: bold; /* Emphasize the question */
  color: #333; /* Consistent text color */
  margin-bottom: 15px;
}

/* Styling for Multiple Choice Options */
.multiple-choice-options {
  display: flex;
  flex-direction: column; /* Stack options vertically */
  gap: 10px; /* Space between each option */
}

/* Styling for Multiple Choice Options Container */
.multiple-choice-options {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center; /* Center align the options to look better visually */
}

/* Styling for Individual Option Buttons */
.multiple-choice-option {
  font-size: 15pt; /* Slightly larger font for readability */
  padding: 8px 12px; /* Adjust padding to make the button smaller and more compact */
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  width: auto; /* Allow the button to fit its content without stretching */
  max-width: 150px; /* Limit width to ensure buttons stay compact */
  text-align: center; /* Center the text inside the button */
}

/* Hover Effect for Buttons */
.multiple-choice-option:hover {
  background-color: #f0f0f0;
  border-color: #007bff;
}

/* Disabled Button Styling */
.multiple-choice-option:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}


/* General page content styling */
.page-content {
  font-family: 'Verdana, sans-serif';
  font-size: 16px;
  color: #444; /* General text color for consistency */
  line-height: 1.5;
  margin-bottom: 20px; /* Space between content sections */
}

/* Container for page turners */
.page-turner-container {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  align-items: center;
  justify-content: space-between; /* Space out page info and buttons */
  gap: 10px;
  padding: 10px;
}

/* Styling for the page info */
.page-info {
  font-size: 18px;
  font-weight: bold;
  flex: 1; /* Allow the page info to take up available space */
}
/* Container for the buttons */
.page-buttons {
  display: flex;
  gap: 10px; /* Space between buttons */
}
/* Styling for Previous and Next buttons */
.prev-button, .next-button {
  font-size: 16px; /* Font size for the buttons */
  padding: 8px 16px; /* Padding for a larger click area */
  background-color: #007BFF; /* Primary color */
  color: white; /* Text color */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Change cursor on hover */
  margin: 0 5px; /* Space between the buttons */
  transition: background-color 0.3s ease; /* Smooth color transition */
}

/* Styling for disabled buttons */
.prev-button:disabled, .next-button:disabled {
  background-color: #ccc; /* Grey color for disabled state */
  cursor: not-allowed;
}

/* Styling for hover effect */
.prev-button:hover:not(:disabled), .next-button:hover:not(:disabled) {
  background-color: #0056b3; /* Darker shade on hover */
}
/* Responsive styling */
@media (max-width: 768px) {
  .page-turner-container {
    flex-direction: column; /* Stack elements on smaller screens */
    align-items: stretch; /* Make items fill the width */
  }

  .page-info {
    text-align: center; /* Center align the text on mobile */
    margin-bottom: 10px;
  }

  .page-buttons {
    justify-content: center; /* Center the buttons on mobile */
  }
}

/* Styling for level and page links */
nav ul li a, .page-info {
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

nav ul li a:hover, .page-info:hover {
  color: #007BFF; /* Same hover color as buttons */
}
.blockquote {
  padding: 10px ;
  margin: 20px 0;
  border-left: 5px solid #000000;
  background-color: #f9f9f9;
  font-style: italic;
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: blue; /* Optional: Add color for visibility */
}

.blockquote.horizontal {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 10px 0; /* Adjusted to reduce margin between words */
}
.words-container.horizontal {
  display: flex;
}
.words-container.horizontal span {
  margin: 2px 5px; /* Reduced margin to bring words closer together */
}
/* Styling for vertical layout blockquotes */
.blockquote.vertical {
  display: block;
  text-align: left; /* Align text for readability */
  margin: 20px 0;
  padding: 10px;
  border-left: 4px solid #000000;
  background-color: #f9f9f9;
  font-style: italic;
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: blue; /* Optional: Add color for visibility */
  width: 100%; /* Ensures the blockquote takes full width of the container */
  box-sizing: border-box; /* Include padding and border in the element's width */
}

/* Container for words in vertical layout */
.words-container.vertical {
  display: flex;
  flex-wrap: wrap; /* Allows words to wrap into rows */
  justify-content: space-between; /* Evenly distribute the words across the container */
  align-items: flex-start;
  max-width: 100%; /* Fit the container width */
  gap: 10px; /* Adjust spacing between words */
  padding: 10px; /* Add padding inside the container for comfort */
  box-sizing: border-box; /* Make sure padding is included in the width */
}

/* Individual word styling in vertical layout */
.words-container.vertical span {
  padding: 5px 8px; /* Reduce padding to make words more compact */
  border-radius: 4px; /* Keep rounded corners for a polished look */
  background-color: #e6e6e6; /* Light gray background to distinguish words visually */
  margin: 0; /* Remove any default margin to maximize space usage */
  flex: 1 1 auto; /* Flex-grow and flex-shrink allow the words to grow or shrink to fit */
  min-width: 80px; /* Set a reasonable minimum width for each word */
}


/* Feedback styling */
.feedback {
  font-size: 15pt;
  margin-top: 15px;
  font-style: italic;
  color: #000000;
}

/* Wrapper for levels and topics */
/* Wrapper for levels and topics */
.levels-topics-wrapper {
  display: flex;
  justify-content: center;
  margin: 20px auto;
  max-width: 1200px; /* Optional: Set a max-width */
  border: 2px solid #ccc; /* Frame around the whole section */
  border-radius: 8px; /* Rounded corners */
  background-color: #f9f9f9; /* Background color for the frame */
  padding: 20px;
}

/* Container for levels and topics inside the wrapper */
.levels-and-topics-container {
  display: flex;
  flex: 1;
  gap: 20px; /* Space between levels and topics */
}

/* Styling for levels container */
.levels-container {
  flex: 1;
}

/* Styling for topics container */
.topics-container {
  flex: 1;
}

/* Styling for headings */
.levels-container h2,
.topics-container h2 {
  font-size: 24px;
  text-align: center;
  margin-bottom: 15px;
  color: #333;
}

/* Styling for levels navigation */
.menu-list {
  list-style: none;
  padding: 0;
}

.menu-list li {
  margin: 10px 0;
}

.menu-list li a {
  font-size: 18px;
  text-decoration: none;
  color: #007BFF;
}

/* Styling for topics list */
.topics-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .levels-and-topics-container {
    flex-direction: column;
    align-items: center;
  }
}
/* CoverModal styles */
.cover-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  text-align: center;
}

.cover-modal-container img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.cover-modal-input {
  padding: 10px;
  margin: 10px 0;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 80%;
  max-width: 300px;
}

.cover-modal-button {
  padding: 10px 20px;
  margin-top: 10px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.cover-modal-button.close {
  background-color: #DC3545;
}

.cover-modal-error {
  color: #FF4D4F;
  margin-top: 10px;
}

/* NavBar container */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #007bff;
  padding: 10px 20px;
  color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Logo */
.navbar-logo a {
  color: white;
  font-size: 1.5em;
  font-weight: bold;
  text-decoration: none;
}

/* Navigation links */
.navbar-links a {
  margin: 0 10px;
  color: white;
  text-decoration: none;
  font-weight: 500;
}

.navbar-links a:hover {
  text-decoration: underline;
}

/* User section */
.navbar-user {
  display: flex;
  align-items: center;
}

/* Status indicators */
.status-indicator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
}

.status-indicator.logged-in {
  background-color: #28a745;
}

.status-indicator.not-logged-in {
  background-color: #ccc;
}

/* Navigation buttons */
.nav-button {
  padding: 5px 10px;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
}

.nav-button.logout-button {
  background-color: #dc3545;
}

.nav-button.login-link {
  background-color: transparent;
}
